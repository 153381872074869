import { defaultTheme } from "react-admin";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import merge from "lodash/merge";

export const theme = createMuiTheme(
  merge({}, defaultTheme.palette, {

    palette: {
      type: 'dark',
      primary: {
      	main: "#90caf9",
      },
      secondary: {
        main: "#424242",
      },
    },
    typography: {
        // Use the system font instead of the default Roboto font.
        fontFamily: [
      		'-apple-system',
      		'BlinkMacSystemFont',
      		'"Segoe UI"',
      		'Roboto',
      		'"Helvetica Neue"',
      		'Arial',
      		'sans-serif',
      		'"Apple Color Emoji"',
      		'"Segoe UI Emoji"',
      		'"Segoe UI Symbol"',
        ].join(','),
    },
  })
);