import * as React from 'react';
import {Fragment,
        useCallback,
        useEffect,
        useState,
        } from 'react';
import {List,
        Datagrid,
        DateField,
        TextField,
        NumberField,
        useGetList,
        useListContext,
        ListContextProvider,
        Filter,
        TextInput,
        } from 'react-admin';

import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Divider } from '@material-ui/core';

const OrderFilter = props => (
    <Filter {...props}>
        <TextInput label='Search by Customer' source='customerName' alwaysOn />
        <TextInput label='Search by Id' source='orderId' allowEmpty />
        <TextInput label='Search by Date' source='purchasedAt' allowEmpty />
    </Filter>
);

//bold price
const useDatagridStyles = makeStyles({
    total: { fontWeight: 'bold' },
});

//define tabs
const tabs = [
    {id: 'ordered', name: 'ordered'},
    {id: 'indesign', name: 'indesign'},
    {id: 'requested', name: 'requested'},
    {id: 'shipped', name: 'shipped'},
    {id: 'cancelled', name: 'cancelled'},
]

//total calc algo
const useGetTotals = filterValues => {
    const { total: totalOrdered } = useGetList(
        'orders',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'ordered' }
    );
    const { total: totalIndesign } = useGetList(
        'orders',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'indesign' }
    );
    const { total: totalRequested } = useGetList(
        'orders',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'requested' }
    );
    const { total: totalShipped } = useGetList(
        'orders',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'shipped' }
    );
    const { total: totalCancelled } = useGetList(
        'orders',
        { perPage: 1, page: 1 },
        { field: 'id', order: 'ASC' },
        { ...filterValues, status: 'cancelled' }
    );

    return {
        ordered: totalOrdered,
        indesign: totalIndesign,
        requested: totalRequested,
        shipped: totalShipped,
        cancelled: totalCancelled,
    };
}

const TabbedDatagrid = props => {

  const listContext = useListContext();
  const { ids, filterValues, setFilters, displayedFilters } = listContext;
  const classes = useDatagridStyles();
  const totals = useGetTotals(filterValues);

  const [ordered, setOrdered] = useState('');
  const [indesign, setIndesign] = useState('');
  const [requested, setRequested] = useState('');
  const [shipped, setShipped] = useState('');
  const [cancelled, setCancelled] = useState('');

  useEffect(() => {
    if (ids && ids !== filterValues.status) {
      switch (filterValues.status) {
        case 'ordered':
          setOrdered(ids);
          break;
        case 'indesign':
          setIndesign(ids);
          break;
        case 'requested':
          setRequested(ids);
          break;
        case 'shipped':
          setShipped(ids);
          break;
        case 'cancelled':
          setCancelled(ids);
          break;
        default:
      }
    }
  }, [ids, filterValues.status]);

  const handleChange = useCallback( (event, value) => {
      setFilters &&
        setFilters(
          { ...filterValues, status: value },
          displayedFilters
        );
    },
    [displayedFilters, filterValues, setFilters]
  );

  return(
    <Fragment>
      <Tabs
        centered
        value={filterValues.status}
        variant='fullWidth'
        indicatorColor="primary"
        onChange={handleChange}
      >
        {tabs.map(choice => (
          <Tab
            key={choice.id}
            label={
              totals[choice.name]
                ? `${choice.name} (${totals[choice.name]})`
                : choice.name
            }
            value={choice.id}
          />
        ))};
      </Tabs>
      <Divider />
      <div>
        {filterValues.status === 'ordered' && (
          <ListContextProvider
            value={{ 
              ...listContext, 
              ids: ordered,
              currentSort: {field: 'createdAt', order: 'ASC'}, 
            }}
          >
            <Datagrid rowClick='edit' {...props} optimized>
              <DateField label='Date' source='createdAt' />
              <TextField label='Order Id' source='tokenId' />
              <TextField label='Customer' source='name' />
              <TextField label='Shipping Address' source='address' />
              {/* number of products purchased */}
              <NumberField
                  source='totalPrice'
                  options={{ style: 'currency', currency: 'USD', }}
                  className={classes.total}
              />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'indesign' && (
          <ListContextProvider
            value={{ 
              ...listContext, 
              ids: indesign,
              currentSort: {field: 'createdAt', order: 'ASC'}, 
            }}
          >
            <Datagrid rowClick='edit' {...props}>
              <DateField label='Date' source='createdAt' />
              <TextField label='Order Id' source='tokenId' />
              <TextField label='Customer' source='name' />
              <TextField label='Shipping Address' source='address' />
              <TextField label='Layover' source='layover' />
              {/* number of products purchased */}
              <NumberField
                  source='totalPrice'
                  options={{ style: 'currency', currency: 'USD', }}
                  className={classes.total}
              />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'requested' && (
          <ListContextProvider
            value={{ 
              ...listContext, 
              ids: requested,
              currentSort: {field: 'createdAt', order: 'ASC'}, 
            }}
          >
            <Datagrid rowClick='edit' {...props}>
              <DateField label='Date' source='createdAt' />
              <TextField label='Order Id' source='tokenId' />
              <TextField label='Customer' source='name' />
              <TextField label='Shipping Address' source='address' />
              <TextField label='Layover' source='layover' />
              {/* number of products purchased */}
              <NumberField
                  source='totalPrice'
                  options={{ style: 'currency', currency: 'USD', }}
                  className={classes.total}
              />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'shipped' && (
          <ListContextProvider
            value={{ 
              ...listContext, 
              ids: shipped,
              currentSort: {field: 'createdAt', order: 'ASC'}, 
            }}
          >
            <Datagrid rowClick='edit' {...props}>
              <DateField label='Date' source='createdAt' />
              <TextField label='Order Id' source='tokenId' />
              <TextField label='Customer' source='name' />
              <TextField label='Shipping Address' source='address' />
              <TextField label='Layover' source='layover' />
              {/* number of products purchased */}
              <NumberField
                  source='totalPrice'
                  options={{ style: 'currency', currency: 'USD', }}
                  className={classes.total}
              />
            </Datagrid>
          </ListContextProvider>
        )}
        {filterValues.status === 'cancelled' && (
          <ListContextProvider
            value={{ 
              ...listContext, 
              ids: cancelled,
              currentSort: {field: 'createdAt', order: 'ASC'}, 
            }}
          >
            <Datagrid rowClick='edit' {...props}>
              <DateField label='Date' source='createdAt' />
              <TextField label='Order Id' source='tokenId' />
              <TextField label='Customer' source='name' />
              <TextField label='Shipping Address' source='address' />
              <TextField label='Layover' source='layover' />
              {/* number of products purchased */}
              <NumberField
                  source='totalPrice'
                  options={{ style: 'currency', currency: 'USD', }}
                  className={classes.total}
              />
            </Datagrid>
          </ListContextProvider>
        )}
      </div>
    </Fragment>
  );
};

const OrderList = props => (
	<List
		{...props}
		perPage={25}
        filterDefaultValues={{ status: 'ordered' }}
        filters={<OrderFilter />}
	>
		<TabbedDatagrid />
	</List>
);

export default OrderList;
