import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import { GlobalStyle } from './global.styles';

ReactDOM.render(
	<div>
		<GlobalStyle />
		<App />
	</div>,
	document.getElementById('root')
);
registerServiceWorker();
