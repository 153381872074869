import * as React from "react";
import {
  Datagrid,
  List,
  TextField,
  ArrayField,
  UrlField,
  BooleanField,
  RichTextField,
  NumberField,
  DateField,
  ArrayInput,
  TextInput,
  NumberInput,
  BooleanInput,
  Edit,
  EditButton,
  SimpleForm,
  SimpleFormIterator,
  Create,
  Show,
  ShowButton,
  SimpleShowLayout,
  Filter,
  SearchInput,
  TopToolbar,
  ListButton,
  ImageField,
} from "react-admin";

import RichTextInput from 'ra-input-rich-text';

import {Typography, Box} from '@material-ui/core'

//title prep
const CollectionTitle =({ record }) => {
  return(
    <span>Collection {record ? `"${record.title}"` : ''}</span>
  )
};

//filter prep
const CollectionFilter = props => (
  <Filter {...props}>
    <SearchInput source="title" alwaysOn />
  </Filter>
);

//back to list button in show
const CollectionShowActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <EditButton basePath={basePath} record={data} />
  </TopToolbar>
);

//back to list button in edit
const CollectionEditActions = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

//back to list button in create
const CollectionCreateActions = ({ basePath }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const Separator = () => <Box pt="1em" />;

export const CollectionList = props => (
    <List
      filters={<CollectionFilter />}
      sort={{ field: 'categoryId', order: 'ASC' }}
      {...props}
    >
        <Datagrid>
            <NumberField label="id" source="categoryId" />
            <TextField label="Category Name" source="title" />
            <RichTextField label="Category Description" source="categoryDescription" />
            <ImageField label="Representative Image" source="categoryImageUrl" />
            {/* # of images here */}
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

const CollectionPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML = {{
    __html:
      'Product Description : ' + record.itemDescription
    }}
  />
);

export const CollectionShow = props => (
    <Show
      actions={<CollectionShowActions />}
      title={<CollectionTitle />}
      {...props}
    >
        <SimpleShowLayout>
          <Typography variant='h5' gutterBottom>
            Category Information
          </Typography>
            <NumberField label="Category Id" source="categoryId" />
            <TextField label="Category Title" source="title" />
            <RichTextField label="Category Description" source="categoryDescription" />
            <ImageField label="Representative Image" source="categoryImageUrl" />
            <BooleanField label="Bigger Image?" source="size" />
            <Separator />
          <Typography variant='h5' gutterBottom>
            Product Information
          </Typography>
            <ArrayField source="items">
              <Datagrid expand={<CollectionPanel />}>
                <NumberField label="Product Id" source="id" />
                <TextField label="Product Name" source="name" />
                <NumberField source="price" options={{ style: 'currency', currency: 'USD' }} />
                <BooleanField label="Sold Out?" source="noInventory" />
                <BooleanField label="Collection?" source="shopCollection" />
                <BooleanField label="Picks?" source="shopPicks" />
                <TextField label="Material" source="material" />
                <ImageField label="Representative Image" source="imageUrl" />
                <UrlField label="Vender URL" source="venderUrl" />
                {/* # of images here */}
              </Datagrid>
            </ArrayField>
            <DateField source="lastupdate" />
            <TextField source="updatedby" />
        </SimpleShowLayout>
    </Show>
);

export const CollectionEdit = props => (
    <Edit
      actions={<CollectionEditActions />}
      title={<CollectionTitle />}
      {...props}
    >
        <SimpleForm warnWhenUnsavedChanges>
          <Typography variant='h5' gutterBottom>
            Category Information
          </Typography>
            <NumberInput label="Category Id" source="categoryId" />
            <TextInput label="Category Title" source="title" />
            <RichTextInput label="Category Description" source="categoryDescription" />
            <TextInput label="Representative Image" source="categoryImageUrl" />
            <BooleanInput label="Bigger Image?" source="size" />
            <Separator />
          <Typography variant='h5' gutterBottom>
            Product Information
          </Typography>
            <ArrayInput source="items">
              <SimpleFormIterator>
                <NumberInput label="Product Id" source="id" />
                <TextInput label="Product Name" source="name" />
                <NumberInput label="Price in $" source="price" />
                <BooleanInput label="Sold Out?" source="noInventory" />
                <BooleanInput label="Collection?" source="shopCollection" />
                <BooleanInput label="Picks?" source="shopPicks" />
                <TextInput label="Material" source="material" />
                <RichTextInput label="Product Description" source="itemDescription" />
                <TextInput label="Representative Image" source="imageUrl" />
                <ArrayInput label="Detail Images" source="images">
                  <SimpleFormIterator>
                    <TextInput />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput label="Vender URL" source="venderUrl" />
                <ArrayInput label="Select Option" source="selectOptions">
                  <SimpleFormIterator>
                    <TextInput />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput label="Custom Description" source="customDescription" />
              </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);

export const CollectionCreate = props => (
    <Create actions={<CollectionCreateActions />} title="Create Collection" {...props}>
        <SimpleForm warnWhenUnsavedChanges>
          <Typography variant='h5' gutterBottom>
            Category Information
          </Typography>
            <NumberInput label="Category Id" source="categoryId" />
            <TextInput label="Category Title" source="title" />
            <RichTextInput label="Category Description" source="categoryDescription" />
            <TextInput label="Representative Image" source="categoryImageUrl" />
            <BooleanInput label="Bigger Image?" source="size" />
            <Separator />
          <Typography variant='h5' gutterBottom>
            Product Information
          </Typography>
            <ArrayInput source="items">
              <SimpleFormIterator>
                <NumberInput label="Product Id" source="id" />
                <TextInput label="Product Name" source="name" />
                <NumberInput label="Price in $" source="price" />
                <BooleanInput label="Sold Out?" source="noInventory" />
                <BooleanInput label="Collection?" source="shopCollection" />
                <BooleanInput label="Picks?" source="shopPicks" />
                <TextInput label="Material" source="material" />
                <RichTextInput label="Product Description" source="itemDescription" />
                <TextInput label="Representative Image" source="imageUrl" />
                <ArrayInput label="Detail Images" source="images">
                  <SimpleFormIterator>
                    <TextInput />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput label="Vender URL" source="venderUrl" />
                <ArrayInput label="Select Option" source="selectOptions">
                  <SimpleFormIterator>
                    <TextInput />
                  </SimpleFormIterator>
                </ArrayInput>
                <TextInput label="Custom Description" source="customDescription" />
              </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

