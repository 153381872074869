import React from 'react';
import CardValue from './CardValue';
import { makeStyles } from '@material-ui/core/styles';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

const useStyles = makeStyles({
	main: {
		flex: '1',
		marginTop: 20,
	},
});

const NewOrders = ({ value }) => {
	const classes = useStyles();
	return (
		<div className = {classes.main}>
			<CardValue
				title='Pending Orders'
				value={value}
				to='/orders?displayedFilters=%7B%7D&filter=%7B"status"%3A"ordered"%7D&order=ASC&page=1&perPage=25&sort=id'
				icon={ShoppingCartIcon}
			/>
		</div>
	)
};

export default NewOrders;