import * as React from 'react';
import {Edit,
		FormWithRedirect,
		Toolbar,
		SaveButton,
		TextField,
		DateField,
		TopToolbar,
		ListButton,
		Labeled,
		SelectInput,
		ArrayField,
		Datagrid,
		NumberField,
            TextInput,
		} from 'react-admin';
import {Box,
		Typography,
		} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RichTextInput from 'ra-input-rich-text';

const useOrderStyles = makeStyles({
	customer: { fontSize: '16px' },
	totalPrice: {
		fontSize: '20px',
	}
});

const OrderTitle = ({ record }) => {
	return(
		<span>Order {record ? `${record.tokenId}` : ''}</span>
	)
};

const Spacer = () => <Box m={1}>&nbsp;</Box>

//back to list button in edit
const OrderEditAction = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
  </TopToolbar>
);

const OrderForm = props => {
  const classes = useOrderStyles();
  return(
    <FormWithRedirect
      {...props}
      render = {formProps => (
      	//custom form layout
      	<form>
      	  <Box p='1ex'>
      	  	{/* Order and Customer Flex Row */}
      	  	<Box display='flex'>
      	  	  
      	  	  <Box flex={2} mr='1em' ml='1em' mt='1em'>

      	  	  	<Typography variant='h6' gutterBottom>
					Order
				</Typography>
      	  	    <Box display='flex'>
      	  	      <Box flex={1}>
      	  	        <Labeled label='Date'>
      	  	        	<DateField
      	  	        		resource='orders'
      	  	        		source='createdAt'
      	  	        		record={formProps.record}
      	  	        		className={classes.customer}
      	  	        		showTime
      	  	        		options = {{
      	  	        			timeZone : 'America/Phoenix',
      	  	        			timeZoneName: 'short'
      	  	        		}}
      	  	        	/>
      	  	        </Labeled>
      	  	      </Box>
      	  	      <Box flex={1}>
      	  	        <Labeled label='Order Id'>
      	  	        	<TextField
      	  	        		resource='orders'
      	  	        		source='tokenId'
      	  	        		record={formProps.record}
      	  	        		className={classes.customer}
      	  	        	/>
      	  	        </Labeled>
      	  	      </Box>
      	  	    </Box>

      	  	    <Spacer />

      	  	    <Box display='flex'>
      	  	      <Box flex={1}>
      	  	        <SelectInput
      	  	          label="Status"
      	  	          source='status'
      	  	          choices={[
      	  	          	{
      	  	          		id: 'ordered',
      	  	          		name: 'ORDERED',
      	  	          	},
      	  	          	{
      	  	          		id: 'indesign',
      	  	          		name: 'INDESIGN',
      	  	          	},
      	  	          	{
      	  	          		id: 'requested',
      	  	          		name: 'REQUESTED',
      	  	          	},
      	  	          	{
      	  	          		id: 'shipped',
      	  	          		name: 'SHIPPED',
      	  	          	},
      	  	          	{
      	  	          		id: 'cancelled',
      	  	          		name: 'CANCELLED',
      	  	          	},
      	  	          ]}
      	  	         />
      	  	      </Box>
      	  	      <Box flex={1}>
      	  	        <SelectInput
      	  	          label="Layover"
      	  	          source='layover'
      	  	          choices={[
      	  	          	{
      	  	          		id: 'TKY-AZ',
      	  	          		name: 'TKY-AZ',
      	  	          	},
      	  	          	{
      	  	          		id: 'TKY',
      	  	          		name: 'TKY',
      	  	          	},
      	  	          	{
      	  	          		id: 'AZ',
      	  	          		name: 'AZ',
      	  	          	},
      	  	          ]}
      	  	         />
      	  	      </Box>
      	  	    </Box>

                      <Box display='flex'>
                        <Box flex={1}>
                          <TextInput label='Tracking ID' source='trackID' />
                        </Box>
                      </Box>

      	  	  </Box>

      	  	  <Box flex={1} mr='1em' ml='1em' mt='1em'>
      	  	    

      	  	  	<Typography variant='h6' gutterBottom>
					Customer
				</Typography>
      	  	    <Box>
      	  	      <Labeled label='Name'>
      	  	      	<TextField
      	  	      		resource='orders'
      	  	      		source='name'
      	  	      		record={formProps.record}
      	  	      		className={classes.customer}
      	  	      	/>	
      	  	      </Labeled>
      	  	    </Box>
      	  	    <Box>
      	  	      <Labeled label='Email'>
      	  	      	<TextField
      	  	      		resource='orders'
      	  	      		source='email'
      	  	      		record={formProps.record}
      	  	      		className={classes.customer}
      	  	      	/>	
      	  	      </Labeled>
      	  	    </Box>
      	  	    <Box>
      	  	      <Labeled label='Country'>
	      	       	  <TextField
	      	       	    resource='orders'
	      	       	    source='country'
	      	       	    record={formProps.record}
	      	       	    className={classes.customer}
	      	       	  />
      	  	      </Labeled>
      	  	    </Box>
      	  	    <Box>
      	  	      <Labeled label='Shipping Address'>
	      	       	  <TextField
	      	       	    resource='orders'
	      	       	    source='address'
	      	       	    record={formProps.record}
	      	       	    className={classes.customer}
	      	       	  />
      	  	      </Labeled>
      	  	    </Box>

      	  	  </Box>

      	  	</Box>
      	  	<Spacer />

      	  	{/* Item Table */}
      	  	<Box display='flex' ml='1em'>
	      	    <Typography variant='h6' gutterBottom>
					Items
				</Typography>
      	  	</Box>
      	  	<Box display='flex' ml='1em'>
      	  	    <ArrayField
      	  	      source='items'
      	  	      resource='orders'
      	  	      record={formProps.record}
      	  	    >
      	  	      <Datagrid>
      	  	      	<TextField label='Item Id' source='id' />
      	  	      	<TextField label='Item Name' source='name' />
      	  	      	<TextField label='Category' source='routerName' />
                              <TextField label='Selected' source='selectedOption' />
                              <TextField label='Custom Box' source='customBox' />
      	  	      	<NumberField label='Unit Price' source='price' options={{style: 'currency', currency: 'USD'}} />
      	  	      	<NumberField label='Quantity' source='quantity' />
      	  	      </Datagrid>
      	  	    </ArrayField>
      	  	</Box>

      	  	<Spacer />

      	  	{/* Total Price */}
      	  	<Box display='flex' ml='1em' mr='1em' justifyContent='flex-end' >
	      	  <Box mr='0.5em'>
	      	  	<Typography variant='h6' gutterBottom>
					Total Price :
				</Typography>
	      	  </Box>
      	  	  <Box ml='0.5em'>
      	  	  	<Typography variant='h6' gutterBottom>
      	  	  		<NumberField
      	  	  		  resource='orders'
      	  	  		  source='totalPrice'
      	  	  		  record={formProps.record}
      	  	  		  className={classes.totalPrice}
      	  	  		  options={{style: 'currency', currency: 'USD',}}
      	  	  		/>
				</Typography>
      	  	  </Box>
      	  	</Box>
      	  	<Box display='flex' ml='1em'>
      	  	</Box>

      	  	{/* misc */}
      	  	<Box display='flex' ml='1em' mr='1em'>
	      	  <Typography variant='h6' gutterBottom>
			    Miscellaneous
			  </Typography>
      	  	</Box>
      	  	<Box display='flex' justifyContent='space-between'>
      	  	  <Box flex={2} mr='1em' ml='1em' mt='1em'>
      	  	    <RichTextInput source='note' />
      	  	  </Box>
      	  	  <Box flex={1} mr='1em' mt='1em' justifyContent='flex-end'>
      	  	  	<Labeled label='Last updated by'>
      	  	      <TextField
      	  	      	resource='orders'
      	  	      	source='updatedby'
      	  	      	record={formProps.record}
      	  	      	className={classes.customer}
      	  	      />
      	  	  	</Labeled>
      	  	  </Box>
      	  	</Box>

      	  </Box>

      	  {/* tool bae setting */}
      	  <Toolbar>
      	  	<Box
      	  	  display='flex'
      	  	  justifyContent='space-between'
      	  	  width='100%'
      	  	>
      	  	  <SaveButton
      	  	  	saving={formProps.saving}
      	  	  	handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
      	  	  />
      	  	</Box>
      	  </Toolbar>
      	</form>
      )}
    />
  );
};

const OrderEdit = props => (
	<Edit
		{...props}
		title={<OrderTitle />}
		actions={<OrderEditAction />}
	>
		<OrderForm />
	</Edit>
);

export default OrderEdit;