import React from 'react';
import {Card,
		CardHeader,
		CardContent,
		} from '@material-ui/core';
import {ResponsiveContainer,
		AreaChart,
		Area,
		XAxis,
		YAxis,
		CartesianGrid,
		Tooltip,
		} from 'recharts';
import { format, subDays, addDays } from 'date-fns';

//generate sequence of numbers and convert to days
const lastDay = new Date();
const lastMonthDays = Array.from({length: 30}, (_, i) => subDays(lastDay, i));
// console.log(lastMonthDays)

//for recharts
const aMonthAgo = subDays(new Date(), 30);
const options = {month: 'short', day: 'numeric',}
const dateFormatter = date => new Date(date).toLocaleDateString('en-US', options);

const MonthlyOrderChart = ({ orders }) => {

  // combime same day orders into one data
  const aggregateOrdersByDay = orders && orders
  	.filter(order => order.status !== 'cancelled')
  	.reduce((acc, curr) => {
  		const day = format(curr.createdAt, 'YYYY-MM-DD');
  		if (!acc[day]) {
  			acc[day] = 0;
  		}
  		acc[day] += curr.totalPrice;
  		return acc;
  	}, {});
  // console.log(aggregateOrdersByDay && aggregateOrdersByDay['01-22'])
  
  const getRevenuePerDay = lastMonthDays.map(date => ({
  		date: date.getTime(),
  		total: aggregateOrdersByDay && (aggregateOrdersByDay[format(date, 'YYYY-MM-DD')] || 0),
  	}));
  // console.log(getRevenuePerDay)

  return (
    <Card>
      <CardHeader title='30 Days Revenue History' />
      <CardContent>
      	<div style={{ width: '100%', height: 300 }}>
      	  <ResponsiveContainer>
  	  		<AreaChart
  	  		  data={getRevenuePerDay}
  	  		  style={{
  	  		  	fontFamily: 'Arial',
  	  		  	fontSize: '0.9rem'
  	  		  }}
  	  		>
  	  		  <defs>
                 <linearGradient
                     id="colorUv"
                     x1="0"
                     y1="0"
                     x2="0"
                     y2="1"
                 >
                     <stop
                         offset="5%"
                         stopColor="#7DB9F1"
                         stopOpacity={0.8}
                     />
                     <stop
                         offset="95%"
                         stopColor="#7DB9F1"
                         stopOpacity={0}
                     />
                 </linearGradient>
              </defs>
  	  		  <XAxis
  	  		    dataKey='date'
  	  		    name='Date'
  	  		    type='number'
  	  		    scale='time'
  	  		    domain={[
  	  		      addDays(aMonthAgo, 1).getTime(),
  	  		      new Date().getTime(),
  	  		    ]}
  	  		    tickFormatter={dateFormatter}
  	  		  />
  	  		  <YAxis
  	  		    dataKey='total'
  	  		    name='Revenue'
  	  		    unit='$'
  	  		  />
  	  		  <CartesianGrid strokeDasharray='3 3' />
  	  		  <Tooltip
  	  		    cursor={{ strokeDasharray: '3 3' }}
  	  		    formatter={value =>
  	  		      new Intl.NumberFormat(undefined, {
  	  		      	style: 'currency',
  	  		      	currency: 'USD',
  	  		      }).format(value)
  	  		    }
  	  		    labelFormatter={label => dateFormatter(label)}
  	  		    labelStyle={{color:'#000'}}
  	  		  />
  	  		  <Area
  	  		    type='monotone'
  	  		    dataKey='total'
  	  		    stroke='#7DB9F1'
  	  		    strokeWidth={2}
  	  		    fill='url(#colorUv)'
  	  		  />
      	  	</AreaChart>
      	  </ResponsiveContainer>
      	</div>
      </CardContent>
    </Card>	
  );
};

export default MonthlyOrderChart;