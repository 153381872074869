import React from 'react';
import {Card,
		CardHeader,
		CardContent,
		} from '@material-ui/core';
import {ResponsiveContainer,
		AreaChart,
		Area,
		XAxis,
		YAxis,
		CartesianGrid,
		Tooltip,
		} from 'recharts';
import { format, subMonths, addMonths } from 'date-fns';

//generate sequence of numbers and convert to 1 year days
const lastDay = new Date();
const lastYearMonths = Array.from({length: 12}, (_, i) => subMonths(lastDay, i));
// console.log(lastYearMonths)

//for recharts
const aYearAgo = subMonths(new Date(), 12);
const options = {month: 'short'}
const optionsForLabel = {month: 'short', year: 'numeric'}
const monthFormatter = month => new Date(month).toLocaleDateString('en-US', options);
const monthFormatterForLabel = month => new Date(month).toLocaleDateString('en-US', optionsForLabel);

const YearlyOrderChart = ({ orders }) => {

  const aggregateOrdersByMonth = orders && orders
    .filter(order => order.status !== 'cancelled')
    .reduce((acc, curr) => {
      const month = format(curr.createdAt, 'YYYY-MM');
      if (!acc[month]) {
      	acc[month] = 0;
      }
      acc[month] += curr.totalPrice;
      return acc;
    }, {});
  // console.log(aggregateOrdersByMonth)
  
  const getRevenuePerMonth = lastYearMonths.map(month => ({
  	  month: month.getTime(),
  	  total: aggregateOrdersByMonth && (aggregateOrdersByMonth[format(month, 'YYYY-MM')] || 0),
    }));
  // console.log(getRevenuePerMonth)

  return (
    <Card>
      <CardHeader title='1 Year Revenue History' />
      <CardContent>
      	<div style={{ width: '100%', height: 300 }}>
      	  <ResponsiveContainer>
      	  	<AreaChart
      	  	  data={getRevenuePerMonth}
      	  	  style={{
      	  	  	fontFamily: 'Arial',
      	  	  	fontSize: '0.9rem'
      	  	  }}
      	  	>
      	  	  <defs>
                 <linearGradient
                     id="colorUv"
                     x1="0"
                     y1="0"
                     x2="0"
                     y2="1"
                 >
                     <stop
                         offset="5%"
                         stopColor="#7DB9F1"
                         stopOpacity={0.8}
                     />
                     <stop
                         offset="95%"
                         stopColor="#7DB9F1"
                         stopOpacity={0}
                     />
                 </linearGradient>
              </defs>
              <XAxis
                dataKey='month'
                name='Month'
                type='number'
                scale='time'
                domain={[
               	  addMonths(aYearAgo, 1).getTime(),
               	  new Date().getTime(),
                ]}
                tickFormatter={monthFormatter}
              />
              <YAxis
                datakey='total'
                name='Revenue'
                unit='$'
              />
              <CartesianGrid strokeDasharray='3 3' />
              <Tooltip
                cursor={{ strokeDasharray: '3 3' }}
  	  		    formatter={value =>
  	  		      new Intl.NumberFormat(undefined, {
  	  		      	style: 'currency',
  	  		      	currency: 'USD',
  	  		      }).format(value)
  	  		    }
  	  		    labelFormatter={label => monthFormatterForLabel(label)}
  	  		    labelStyle={{color:'#000'}}
              />
      	  	  <Area
      	  	  	type='monotone'
      	  	    dataKey='total'
      	  	    stroke='#7DB9F1'
      	  	    strokeWidth={2}
      	  	    fill='url(#colorUv)'
      	  	  />
      	  	</AreaChart>
      	  </ResponsiveContainer>
      	</div>
      </CardContent>
    </Card>
  );
};

export default YearlyOrderChart;