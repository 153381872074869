import React from 'react';
import CardValue from './CardValue';
import { makeStyles } from '@material-ui/core/styles';
import DollarIcon from '@material-ui/icons/AttachMoney';

const useStyles = makeStyles({
	main: {
		flex: '1',
		marginTop: 20,
	},
});

export const MonthlyRevenue = ({ value }) => {
	const classes = useStyles();
	return (
		<div className = {classes.main}>
			<CardValue
				title='Monthly Revenue'
				value={'$'.concat(value)}
				to='/orders?displayedFilters=%7B%7D&filter=%7B"status"%3A"delivered"%7D&order=ASC&page=1&perPage=25&sort=id'
				icon={DollarIcon}
			/>
		</div>
	)
};

export const YearlyRevenue = ({ value }) => {
	const classes = useStyles();
	return (
		<div className = {classes.main}>
			<CardValue
				title='Revenue Year To Date'
				value={'$'.concat(value)}
				to='/orders?displayedFilters=%7B%7D&filter=%7B"status"%3A"delivered"%7D&order=ASC&page=1&perPage=25&sort=id'
				icon={DollarIcon}
			/>
		</div>
	)
};