import React from 'react';
import CardValue from './CardValue';
import { makeStyles } from '@material-ui/core/styles';
import CustomerIcon from '@material-ui/icons/PersonAdd';

const useStyles = makeStyles({
	main: {
		flex: '1',
		marginTop: 20,
	},
});

const NewUsers = ({ value }) => {
	const classes = useStyles();
	return (
		<div className = {classes.main}>
			<CardValue
				title='Monthly New Users'
				value={value}
				to='/users'
				icon={CustomerIcon}
			/>
		</div>
	)
};

export default NewUsers;