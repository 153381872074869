import React from 'react';
import {Card,
		CardHeader,
		List,
		ListItem,
		ListItemText,
		ListItemSecondaryAction,
		ListItemAvatar,
		Divider,
		Typography,
		Avatar,
		} from '@material-ui/core';
import { Link } from 'react-router-dom';

const options = {weekday: 'short', year: 'numeric', month: 'short', day: 'numeric',}

const AvatarField = ({ record }) => <Avatar src='test' />;

const PendingOrders = ({ orders }) => {
	return (
		<Card>
			<CardHeader align='left' title='Pending Orders' />
			<Divider />
			<List dense={true}>
				{
					orders.length !== 0 ? 
					orders.map(record => {
						const {createdAt, name, items, country, id} = record;
						return (
							<ListItem
								key={id}
								component={Link}
								to={`/orders/${record.id}`}
								button
							>
								<ListItemAvatar>
									<AvatarField />
								</ListItemAvatar>
								<ListItemText
									primary = {
										new Date(createdAt).toLocaleString('en-US',options)
									}
									secondary = {
										<span>
											by {name}, {country}, {items.length} items
										</span>
									}
								/>
								<ListItemSecondaryAction>
									<span>
										<Typography style={{fontSize:'18px'}}>${record.totalPrice}</Typography>
									</span>
								</ListItemSecondaryAction>
							</ListItem>
						)
					})
					:
					<ListItem>
						<Typography variant='body2'>
							No pending orders	
						</Typography>
					</ListItem>
				}
			</List>
		</Card>
	);
};

export default PendingOrders;