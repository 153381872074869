//basic library
import * as React from "react";
import {Admin,
        Resource,
        Title,
        Login,
        } from "react-admin";
import {FirebaseDataProvider,
        FirebaseAuthProvider,
        } from "react-admin-firebase";

//material-ui library
import CollectionIcon from '@material-ui/icons/Collections';
import UserIcon from '@material-ui/icons/People'
import OrderIcon from '@material-ui/icons/AttachMoney'

//unique library
import { firebaseConfig as config } from './FIREBASE_CONFIG';
// comment out if to use firebase authProvider
// import authProvider from './authProvider';
import {CollectionList,
        CollectionEdit,
        CollectionCreate,
        CollectionShow,
        } from './collections';
import {UserList} from './users';
import OrderList from './ordersList';
import OrderEdit from './ordersEdit';
import Dashboard from './dshbd/dashboard';
import { theme } from './theme';

const dataProvider = FirebaseDataProvider(config);
//remove comment out here if to use firebase authProvider and commet out above authProvider file
const authProvider = FirebaseAuthProvider(config);

const MyLoginPage = () => (
  <Login 
    backgroundImage="https://svgshare.com/i/T4k.svg"
  />
);

const App = () => {

  return (
    <Admin
      dataProvider={dataProvider}
      authProvider={authProvider}
      dashboard={Dashboard}
      theme={theme}
      loginPage={MyLoginPage}
    >
      <Title title = "Mionosuke : " />
      <Resource
        name="orders"
        icon={OrderIcon}
        list={OrderList}
        edit={OrderEdit}
        options={{ label: "Orders"}}
      />
      <Resource
        name="users"
        icon={UserIcon}
        list={UserList}
        options={{ label: "Users"}}
      />
      <Resource
        name='collections'
        icon={CollectionIcon}
        list={CollectionList}
        edit={CollectionEdit}
        create={CollectionCreate}
        show={CollectionShow}
        options={{ label: "Collections"}}
      />
    </Admin>
  );
}

export default App;
