import React from 'react';
import { useQuery } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles';
import {Card,
		    CardContent,
		    CardHeader,
        Box,
        Typography,
        Divider,
		    } from '@material-ui/core';
import { startOfMonth, startOfYear, subDays } from 'date-fns';
import { MonthlyRevenue, YearlyRevenue } from './Revenue'
import NewOrders from './NewOrders';
import NewUsers from './NewUsers';
import PendingOrders from './PendingOrders';
import MonthlyOrderChart from './MonthlyOrderChart';
import YearlyOrderChart from './YearlyOrderChart';

const useStyles = makeStyles({
  card: {
    minWidth: 275,
    margin: '1em',
  },
});

const Dashboard = () => {
	const classes = useStyles();

  //get query from firestore for all orders. note filter payload can't have gte/lte as a range query for some reason
  const { data: confirmedOrders } = useQuery({
      type: 'getList',
      resource: 'orders',
      payload: {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'totalPrice', order: 'ASC' },
          filter: {
              // status: 'delivered',
          },
      },
  })

  //get query from firestore for pending orderes.
  const { total:nbPendingOrders, data: pendingOrders } = useQuery({
      type: 'getList',
      resource: 'orders',
      payload: {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'totalPrice', order: 'ASC' },
          filter: {
              status: 'ordered',
          },
      },
  })
  // console.log(pendingOrders)

  //get query from firestore for users.
  const { data: newCustomers } = useQuery({
      type: 'getList',
      resource: 'users',
      payload: {
          pagination: { page: 1, perPage: 10 },
          sort: { field: 'createdAt', order: 'ASC' },
      },
  })

  //order this month
  const today = Date.now();
  const ordersThisMonth =
    confirmedOrders && confirmedOrders.filter(order => order.createdAt >= startOfMonth(today));
  // console.log(ordersThisMonth)

  //revenue this month
  const revenueThisMonth =
    ordersThisMonth && ordersThisMonth.reduce((acc, order) => acc + order.totalPrice, 0);

  //order this year
  const ordersThisYear = confirmedOrders && confirmedOrders.filter(order=>
    order.createdAt >= startOfYear(today));

  //revenue this month
  const revenueThisYear =
    ordersThisYear && ordersThisYear.reduce((acc, order) => acc + order.totalPrice, 0);

  //N of new customer this month
  const nbNewCustomers =
    newCustomers && newCustomers
      .filter(user => user.createdAt >= startOfMonth(today)).length;
  // console.log(newCustomersList)

  // 30days order list
  const ordersThirtyDays = 
    confirmedOrders && confirmedOrders.filter(order => order.createdAt >= subDays(today,30));
  // console.log(ordersThirtyDays)

  // 1yr order list
  const ordersOneYear = 
    confirmedOrders && confirmedOrders.filter(order => order.createdAt >= subDays(today,365));

	return(
		<Box>
      {/* first row */}
      <Box display='flex'>
        <Box flex={1} mb='1em' mr='1.5em' ml='1em'>
          <MonthlyRevenue value={revenueThisMonth ? revenueThisMonth.toLocaleString(0) : '-'} />
          <NewOrders value={nbPendingOrders ? nbPendingOrders : '-'} />
        </Box>
        <Box flex={1} mb='1em' mr='1em' ml='0.5em'>
          <YearlyRevenue value={revenueThisYear ? revenueThisYear.toLocaleString(0) : '-'} />
          <NewUsers value={nbNewCustomers ? nbNewCustomers : '-'} />
        </Box>
      </Box>
      <Box display='flex'>
        <Box flex={1} m='1em'>
          <MonthlyOrderChart orders={ordersThirtyDays} />
        </Box>
        <Box flex={1} m='1em'>
          <YearlyOrderChart orders={ordersOneYear} />
        </Box>
      </Box>
      <Box display='flex'>
        <Box flex={1} m='1em'>
          <PendingOrders orders={pendingOrders ? pendingOrders : []} />
        </Box>
      </Box>
			<Card className={classes.card}>
				<CardHeader
					title="Readme First"
				/>
        <Divider />
				<CardContent>
          <Typography variant='h6'>About Database</Typography>
					<Typography variant='body2'>
						Mionosuke database consists of 3 components - users, collections, and orders.
            Users database includes those who have been successfully authorized by Google Firebase,
            and are given as read/write right.
            Collections database includes all products information including category, recommendation,
            and product detail.
            Note that image is not stored in those firestore database but stored in firebase storage or thirdparty server,
            so Collections database only has a link to get the api link.
            Orders database includes any order related data, such as who purchased, when the items were purchased,
            and what items were purchased, as well as key id to refer stripe info using 'tokenId'.
					</Typography>
          <br />
          <Typography variant='h6'>Operation</Typography>
          <Typography variant='body2'>
            Once customer purchased through mionosuke.com, order data is uploaded to firebase and pushed to this admin dashboard. <br />
            Admin is supposed to manage 2 operation sequences for each order to get all operations completed(item delivered). <br /><br />
            1st sequence is 'status' management which have 5 status - ordered, requested, shipped, delivered, and cancelled.
            <ul>
              <li>Ordered: When customer completes payment through stripe at Mionosuke.com and order data is pushed to the dashboard</li>
              <li>In Design: If the product requires handmade design and when admin requestes designer to create images</li>
              <li>Requested: When admin requestes drop shipper to manufacture product</li>
              <li>Shipped: When admin gets shipment notice from drop shipper and send email to customer</li>
              <li>Cancelled: When customer cancels and need refund process</li>
            </ul>
            <br />
            2nd sequence is 'layover' management, but it is currently not used as it was designed to ship from japan with either tokyo or arizona layovered and this layover option was supposed to be managed
          </Typography>
          <br />
          <Typography variant='h6'>Image Size</Typography>
          <Typography variant='body2'>
            <ul>
              <li>Category: W800px x H600px</li>
              <li>Product: W480px x H700px</li>
            </ul>
          </Typography>
				</CardContent>
			</Card>
		</Box>
	)
}; 

export default Dashboard;