export const firebaseConfig = {
    apiKey: "AIzaSyDlHf2o2BOjhhTwYkYSq54nwDgc0UEYVbE",
    authDomain: "mionosuke-db.firebaseapp.com",
    projectId: "mionosuke-db",
    storageBucket: "mionosuke-db.appspot.com",
    messagingSenderId: "279213409754",
    appId: "1:279213409754:web:4b83284dd426dee888ace3",
    measurementId: "G-49H2YT1687"

    // apiKey: "AIzaSyCaSJopvz3CBeK29fQ03QwP5NzUXY6nOsk",
    // authDomain: "jpn-trad.firebaseapp.com",
    // projectId: "jpn-trad",
    // storageBucket: "jpn-trad.appspot.com",
    // messagingSenderId: "644491309825",
    // appId: "1:644491309825:web:16aa2e1dba60554342a100",
    // measurementId: "G-5Q6H66NS5W"
};