import React, { createElement } from 'react';
import Card from '@material-ui/core/Card';
import { makeStyles, Typography, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import cartoucheDark from './cartoucheDark.png';

const useStyles = makeStyles({
	card: {
		overflow: 'inherit',
		minHeight: 52,
		display: 'flex',
		flexDirection: 'column',
		flex: '1',
		'& a': {
			textDecoration: 'none',
			color: 'inherit',
		},
	},
	main: {
		overflow: 'inherit',
		height: '100%',
		display: 'flex',
		padding: 16,
		justifyContent: 'space-between',
		alignItems: 'center',
		background: `url(${cartoucheDark}) no-repeat`,
	},
});

const CardValue = ( props ) => {
	const { icon, to, title, value } = props;
	const classes = useStyles();
	return (
		<Card className={classes.card}>
			<Link to={to}>
				<div className={classes.main}>
					<Box width='3em'>
						{createElement(icon, { fontSize: 'large' })}
					</Box>
					<Box textAlign='right'>
						<Typography color='textSecondary'>{title}</Typography>
						<Typography variant='h5' component='h2'>{value}</Typography>
					</Box>
				</div>
			</Link>
		</Card>
	);
};

export default CardValue;