import * as React from 'react';
import {
	List,
	Datagrid,
	TextField,
	DateField,
	EmailField,
	TextInput,
	DateInput,
	Filter,
} from 'react-admin';

const UserFilter = props => (
	<Filter {...props}>
		<TextInput label='Search Email' source="email" alwaysOn />
		<TextInput label='Search Name' source="displayName" allowEmpty />
		<TextInput label='Search Id' source="id" allowEmpty />
		<DateInput label='Search Create Date' source="createdAt" allowEmpty />
	</Filter>
);

export const UserList = props => (
    <List
    	filters={<UserFilter />}
    	{...props}
    	sort={{ field: 'createdAt', order: 'DESC' }}
    >
        <Datagrid>
            <TextField source="id" />
            <DateField source="createdAt" />
            <TextField source="displayName" />
            <EmailField source="email" />
        </Datagrid>
    </List>
);